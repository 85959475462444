import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, Col, Form, Input, Layout, message, Modal, Row, Select, Spin, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Asset } from './model'
import { DeleteAssetForWOByTicketRelatedAssetId, getLocation, updateAsset } from './service'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAssetFilter, searchFetch, status } from '../../asset-management'
import { decryptDataVspace } from '../../../common-misc'
import { useParams } from 'react-router-dom'

interface Param {
    data: Asset
    deleteData: Function
    isDisableAsset: boolean
}

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset,
        filterAsset: state.filterAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    updateAsset: (value: Asset) => Promise<number>
    getAssetFilter: () => Promise<number>
}

type Props = Param & FormComponentProps & StateProps & DispatchProps
const { Content } = Layout
const { Option } = AutoComplete

const AssetContent: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldsError } = props.form
    const data = props.data
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [computerName, setComputerName] = useState<string[]>(props.filterAsset.computerName?.slice(0, 20) || [])
    const dataStatus: Array<string> = status
    const [deptName, setDeptName] = useState<string[]>(props.filterAsset.deptName?.slice(0, 20) || [])
    const { id } = useParams()

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setVspaceName(email)
        }
    }, [])

    if (Object.keys(props.filterAsset).length === 0) {
        props.getAssetFilter().then(() => {
            setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
            setLocation(props.filterAsset.location?.slice(0, 20) || [])
            setComputerName(props.filterAsset.computerName?.slice(0, 20) || [])
            setDeptName(props.filterAsset.deptName?.slice(0, 20) || [])
            console.log('load filter success')
        })
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field])
    }

    const save = (e) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            // Call API
            setLoading(true)
            const dataUpdate = data
            dataUpdate.employeeId = values.employeeId
            dataUpdate.location = values.location
            dataUpdate.status = values.status
            dataUpdate.computerName = values.computerName
            dataUpdate.lastModifiedBy = vSpaceName
            dataUpdate.deptName = values.deptName
            dataUpdate.workOrderId = id
            delete dataUpdate.ticketId
            props.updateAsset(dataUpdate).then(() => {
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                message.error(`You have unSuccessfully Update the data. ${err}`)
            })
            setIsEdit(!isEdit)
        })
    }

    const modalConfirmRemove = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to remove this asset ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                // window.location.href = '/Setting'
                if (props.deleteData) {
                    props.deleteData(props.data.id)
                    if (props.data.ticketRelatedAssetId && window.location.pathname.includes('WoFormEdit')) {
                        DeleteAssetForWOByTicketRelatedAssetId(props.data.ticketRelatedAssetId!!).catch((err) => {
                            message.error(`You have unSuccessfully delete the data. ${err}`)
                        })
                    }
                }
            }
        })
    }

    const modalConfirmCancelEdit = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to cancel asset editing ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                setIsEdit(!isEdit)
                // window.location.href = '/Setting
            }
        })
    }

    const handleSearch = async (value: string) => {
        const tempLocation = await getLocation(value)
        setLocation(tempLocation)
    }

    return (
        <Content className='BG_Gray' style={{ margin: '0px 0px', padding: 5, marginBottom: 8 }} >
            {!isEdit ? <>
                <Row>
                    <Col style={{ textAlign: 'right' }}>
                        <Button type="default" icon="edit" size="small" onClick={() => setIsEdit(!isEdit)} disabled={props.isDisableAsset} />&nbsp;
                        <Button type="default" icon="delete" size="small" onClick={() => modalConfirmRemove()} disabled={props.isDisableAsset} />
                    </Col>
                </Row>
                <Row style={{ lineHeight: '120%', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    <Col span={11} xs={24} sm={24} md={24} lg={11} className="TextFrontDesc"> Serial No :
                        <span className="Desc_text_gray_2"> {props.data.serialNo}</span>
                    </Col>
                    <Col span={13} xs={24} sm={24} md={24} lg={13} className="TextFrontDesc"> Status : <span className="Desc_text_gray_2"> {(props.data.status || '').length > 50
                        ? <Tooltip placement="bottom" title={props.data.status}> {(props.data.status || '').substring(0, 20)}... </Tooltip> : props.data.status}
                    </span>
                    </Col>
                </Row>
                <Row style={{ lineHeight: '300%', paddingLeft: 20, paddingRight: 20 }}>
                    <Col span={11} xs={24} sm={24} md={24} lg={11} className="TextFrontDesc">Employee ID : <span className="Desc_text_gray_2"> {(props.data.employeeId || '').length > 20
                        ? <Tooltip placement="bottom" title={props.data.employeeId}> {props.data.employeeId?.substring(0, 20)}... </Tooltip> : props.data.employeeId}
                    </span>
                    </Col>
                    <Col span={13} xs={24} sm={24} md={24} lg={13} className="TextFrontDesc"> Location : <span className="Desc_text_gray_2"> {(props.data.location || '').length > 50
                        ? <Tooltip placement="bottom" title={(props.data.location || '')}> {(props.data.location || '').substring(0, 50)}... </Tooltip> : props.data.location}
                    </span>
                    </Col>
                </Row>
                <br />
            </> : <>
                <Spin spinning={isLoading}>
                    <Row>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type="default" icon="save" size="small" onClick={save} disabled={hasErrors(getFieldsError())} />&nbsp;
                            <Button type="default" icon="close" size="small" onClick={() => modalConfirmCancelEdit()} />
                        </Col>
                    </Row>
                    <Form layout="horizontal" style={{ padding: 20 }}>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Serial No : <span className="Desc_text_gray_2" >{props.data.serialNo}</span></Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">Kbank Barcode : <span className="Desc_text_gray_2">
                                {props.data.kbankBarcode !== undefined ? <Tooltip placement="bottom" title={props.data.kbankBarcode}> {props.data.kbankBarcode?.length > 50 ? props.data.kbankBarcode.substring(0, 50).concat('...') : props.data.kbankBarcode!!} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Brand : <span className="Desc_text_gray_2">
                                {props.data.brand !== undefined ? <Tooltip placement="bottom" title={props.data.brand}> {props.data.brand?.length > 25 ? props.data.brand.substring(0, 50).concat('...') : props.data.brand} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">Model : <span className="Desc_text_gray_2">
                                {props.data.model !== undefined ? <Tooltip placement="bottom" title={props.data.model}> {props.data.model?.length > 25 ? props.data.model.substring(0, 50).concat('...') : props.data.model} </Tooltip>
                                    : null}
                            </span>
                            </Col>

                        </Row>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> City :
                                <span className="Desc_text_gray_2">
                                    {props.data.city !== undefined ? <Tooltip placement="bottom" title={props.data.city}> {props.data.city?.length > 50 ? props.data.city.substring(0, 50).concat('...') : props.data.city} </Tooltip>
                                        : null}
                                </span>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc"> Zone : <span className="Desc_text_gray_2">
                                {props.data.zone !== undefined ? <Tooltip placement="bottom" title={props.data.zone}> {props.data.zone?.length > 50 ? props.data.zone.substring(0, 50).concat('...') : props.data.zone} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                        </Row>
                        <Row style={{ lineHeight: '200%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ marginTop: '5px' }} className="TextFrontDesc" > Device Type : <span className="Desc_text_gray_2">
                                {props.data.deviceType !== undefined ? <Tooltip placement="bottom" title={props.data.deviceType}> {props.data.deviceType?.length > 25 ? props.data.deviceType.substring(0, 50).concat('...') : props.data.deviceType!!} </Tooltip>
                                    : null}
                            </span>
                            </Col>
                            <Col span={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <Col span={8} style={{ marginTop: '5px' }}>
                                        <span className="TextFrontDesc"> Dept Name : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Col>
                                    <Col span={16}>
                                        {getFieldDecorator('deptName',
                                            {
                                                initialValue: props.data.deptName || undefined,
                                                rules: [{ required: true, whitespace: true, message: 'Dept Name is required' }]
                                            })(
                                                <AutoComplete style={{ width: 200, textAlign: 'left' }} placeholder="Dept_Name" onChange={(value) => { searchFetch(value, props.filterAsset.deptName || [], setDeptName!!) }}
                                                    dataSource={deptName || []}>
                                                    <Input style={{ maxWidth: '100%' }} type="text" placeholder="Dept_Name" maxLength={15} />
                                                </AutoComplete>
                                            )}
                                    </Col>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ lineHeight: '150%' }}>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <Col span={8} style={{ marginTop: '5px' }}>
                                        <span className="TextFrontDesc"> Employee ID : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Col>
                                    <Col span={16}>
                                        {getFieldDecorator('employeeId',
                                            {
                                                initialValue: props.data.employeeId || undefined,
                                                rules: [{ required: true, whitespace: true, message: 'Employee ID is required' }]
                                            })(
                                                <AutoComplete style={{ width: 200, textAlign: 'left' }} placeholder="Employee_ID" onChange={(value) => { searchFetch(value, props.filterAsset.employeeID || [], setEmployeeID!!) }}
                                                    dataSource={employeeID || []}>
                                                    <Input style={{ maxWidth: '100%' }} type="text" placeholder="Employee_ID" maxLength={15} />
                                                </AutoComplete>
                                            )}
                                    </Col>
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <Col span={8} style={{ marginTop: '5px' }}>
                                        <span className="TextFrontDesc"> Computer Name : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Col>
                                    <Col span={16}>
                                        {getFieldDecorator('computerName',
                                            {
                                                initialValue: props.data.computerName || undefined,
                                                rules: [{ required: true, whitespace: true, message: 'Computer Name is required' }]
                                            })(
                                                <AutoComplete style={{ width: 200, textAlign: 'left' }} placeholder="Computer Name" onChange={(value) => { searchFetch(value, props.filterAsset.computerName || [], setComputerName!!) }}
                                                    dataSource={computerName || []}>
                                                    <Input style={{ maxWidth: '100%' }} type="text" placeholder="Computer Name" maxLength={15} />
                                                </AutoComplete>
                                            )}
                                    </Col>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">
                                <Form.Item className="TextFrontDesc">
                                    <Col span={8} style={{ marginTop: '5px' }}>
                                        <span className="TextFrontDesc"> Location : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Col>
                                    <Col span={16}>
                                        {getFieldDecorator('location',
                                            {
                                                initialValue: props.data.location,
                                                rules: [{ required: true, whitespace: true, message: 'Location is required' }]
                                            })(
                                                // <AutoComplete style={{ width: 200 }} onSearch={(e) => handleSearch(e)} placeholder="Location"
                                                //     dataSource={location || []}>
                                                //     <Input maxLength={255}/>
                                                // </AutoComplete>
                                                <Select
                                                    onSearch={(value) => { searchFetch(value, props.filterAsset.location || [], setLocation!!) }}
                                                    style={{ width: 200 }}
                                                    placeholder={'Location'}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(inputValue, option) =>
                                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                >
                                                    {(location || []).map((item, index) => {
                                                        return <Option value={item} key={index} title={item}>{item}</Option>
                                                    })}
                                                </Select>
                                            )}
                                    </Col>
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} className="TextFrontDesc">
                                <Form.Item >
                                    <Col span={8} style={{ marginTop: '5px' }}>
                                        <span className="TextFrontDesc"> Status : </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Col>
                                    <Col span={16}>
                                        {getFieldDecorator('status',
                                            {
                                                initialValue: props.data.status,
                                                rules: [{ required: true, whitespace: true, message: 'Status is required' }]
                                            })(
                                                <Select placeholder="Select a Status" style={{ width: 200 }} >
                                                    {dataStatus.map((it, index) => {
                                                        return <Option value={it!!} key={index} > {it}</Option>
                                                    })}
                                                </Select>
                                            )}
                                    </Col>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                    </Form>
                </Spin>
            </>}

        </Content>
    )
}

const MyAssetContent = Form.create<Props>({ name: 'AssetContent' })(AssetContent)
export default connect(mapStateToProps, {
    updateAsset, getAssetFilter
})(MyAssetContent)
