/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Col,
    DatePicker,
    Icon,
    Input,
    message,
    Modal,
    Row,
    Select,
    Upload
} from 'antd'
import {Link, useHistory} from 'react-router-dom'
import {Space} from '../../common-components/Space'
import {checkFieldDuplicate, createAssetOwnerLocation, getAssetFilter, searchFetch} from './service'
import {UploadFile} from 'antd/lib/upload/interface'
import Form, {FormComponentProps} from 'antd/lib/form'
import exportIcon from '../../common-file/icon-file'
import {AssetOwnerLocation, status} from './model'
import FSS from '../../file-server-storage'
import {UploadLink} from '../../file-server-storage/model'
import {StoreState} from '../../store'
import {decryptDataVspace} from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        filterAsset: state.filterAsset
    }
}

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & StateProps & DispatchProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

const AssetForm: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const history = useHistory()
    const [vSpaceName, setvSpaceName] = useState<string>('')
    const { getFieldDecorator } = props.form
    const [isLoading, setIsLoading] = useState<boolean>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [city, setCity] = useState<string[]>(props.filterAsset.city?.slice(0, 20) || [])
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [deviceType, setDeviceType] = useState<string[]>(props.filterAsset.deviceType?.slice(0, 20) || [])
    const [computerName, setComputerName] = useState<string[]>(props.filterAsset.computerName?.slice(0, 20) || [])
    const [brand, setBrand] = useState<string[]>(props.filterAsset.brand?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [model, setModel] = useState<string[]>(props.filterAsset.model?.slice(0, 20) || [])
    const [deptName, setDeptName] = useState<string[]>(props.filterAsset.deptName?.slice(0, 20) || [])
    const [zone, setZone] = useState<string[]>(props.filterAsset.zone?.slice(0, 20) || [])

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email.toString()
            setvSpaceName(email)
        }
        loadFilter()
    }, [])

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setBrand(props.filterAsset.brand?.slice(0, 20) || [])
                setCity(props.filterAsset.city?.slice(0, 20) || [])
                setComputerName(props.filterAsset.computerName?.slice(0, 20) || [])
                setDeptName(props.filterAsset.deptName?.slice(0, 20) || [])
                setDeviceType(props.filterAsset.deviceType?.slice(0, 20) || [])
                setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
                setLocation(props.filterAsset.location?.slice(0, 20) || [])
                setModel(props.filterAsset.model?.slice(0, 20) || [])
                setZone(props.filterAsset.zone?.slice(0, 20) || [])
            })
        }
    }

    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    )

    const replaceName = (name: string) => {
        return name.replace(/ /g, '_')
    }

    const validateSerialNoUniqueness = (_: any, value: any, callback) => {
        // clearTimeout(searchFieldTimeout)
        if (value) {
            // searchFieldTimeout = setTimeout(() => {
            checkFieldDuplicate(value, undefined).then(() => {
                callback()
            }).catch((err: Error) => {
                if (err.message === 'Request failed with status code 409') {
                    callback('Serial No. is already exists. It must be unique. Please try another one.')
                } else {
                    message.error(err.message)
                }
            })
            // }, 1000)
        } else {
            callback()
        }
    }

    const validateKbankBarcodeUniqueness = (_: any, value: any, callback) => {
        // clearTimeout(searchFieldTimeout)
        if (value) {
            // searchFieldTimeout = setTimeout(() => {
            checkFieldDuplicate(undefined, value).then(() => {
                callback()
            }).catch((err: Error) => {
                if (err.message === 'Request failed with status code 409') {
                    callback('Kbank Barcode is already exists. It must be unique. Please try another one.')
                } else {
                    message.error(err.message)
                }
            })
            // }, 1000)
        } else {
            callback()
        }
    }

    const customCheckDuplicateInput = (name: string,
        required: boolean = false,
        validator: Object[]) => {
        return (
            <Row>
                <Col xl={4} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: 255, message: 'message max 250 characters' },
                                    ...validator
                                ]
                            })(
                                <Input placeholder={`${name}`} id={`${name}`} />
                            )}
                        </Form.Item>

                    </Col>
                </Col>
                <Col xl={4} md={0} />
            </Row>
        )
    }

    const customSelectWithFilter = (name: string,
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        setState?: Function,
        maxInput?: number) => {
        return (
            <Row>
                <Col xl={4} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` }
                                ]
                            })(
                                <Select placeholder={`${name}`}
                                    onSearch={(value) => { searchFetch(value, fullData || [], setState!!) }}
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) =>
                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                    {(autoCompleteData || []).map((item, index) => {
                                        return <Option value={item} key={index} title={item}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                    </Col>
                </Col>
                <Col xl={4} md={0} />
            </Row>
        )
    }

    const customInput = (name: string,
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        setState?: Function,
        maxInput?: number) => {
        return (
            <Row>
                <Col xl={4} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` }
                                ]
                            })(
                                autoCompleteData.length > 0 ? (
                                    <AutoComplete
                                        dataSource={autoCompleteData}
                                        placeholder={`${name}`}
                                        id={`${name}`}
                                        // filterOption={(inputValue, option) =>
                                        //     (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        // }
                                        onChange={(value) => { searchFetch(value, fullData || [], setState!!) }}
                                    />
                                ) : (
                                    <Input placeholder={`${name}`} id={`${name}`} maxLength={maxInput}/>
                                )
                            )}
                        </Form.Item>

                    </Col>
                </Col>
                <Col xl={4} md={0} />
            </Row>
        )
    }

    const createOption = (dataSource: string[]) => {
        return dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
    }

    const customSelect = (name: string, options: string[]) => {
        return (
            <Row>
                <Col xl={4} md={0} />
                <Col xl={16} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                initialValue: options[5]
                            })(
                                <Select
                                    placeholder='select status'
                                    style={{ width: '100%' }}
                                >{createOption(options)}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Col>
                <Col xl={4} md={0} />
            </Row>
        )
    }

    const inputDate = (name: string) => {
        return (
            <Row>
                <Form.Item>
                    <Col xl={4} md={0} />
                    <Col xl={16} style={{ marginTop: 10 }}>
                        <Col xl={6} md={24}>
                            <div style={{ textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }}>
                                {name}
                            </div>
                        </Col>
                        <Col xl={18} md={24}>
                            {getFieldDecorator(replaceName(name))(
                                <DatePicker placeholder={`${name}`} style={{ width: '100%' }} />
                            )}
                        </Col>
                    </Col>
                    <Col xl={4} md={0} />
                </Form.Item>
            </Row>
        )
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                // fileList = fileList.slice(-2);
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const assetOwnerLocation: AssetOwnerLocation = {
                    serialNo: values.Serial_No,
                    kbankBarcode: values.Kbank_Barcode,
                    dateInService: values.Date_in_service,
                    expiryDate: values.Expiry_date,
                    invoiceDate: values.Invoice_date,
                    poNumber: values.PO_Number,
                    deviceType: values.Device_Type,
                    computerName: values.Computer_Name,
                    brand: values.Brand,
                    employeeId: values.Employee_id,
                    model: values.Model,
                    monitorType: values.Monitor_Type,
                    monitorBrand: values.Monitor_Brand,
                    monitorSerial: values.Monitor_Serial,
                    status: values.Asset_Status,
                    company: values.Company,
                    group: values.Group,
                    deptName: values.Dept_Name,
                    description: values.Description,
                    phone: values.Phone,
                    city: values.City,
                    location: values.Location,
                    zone: values.Zone,
                    country: values.Country,
                    regional: values.Regional,
                    dataOrigin: 'add',
                    createdBy: vSpaceName,
                    lastModifiedBy: vSpaceName
                }
                if (fileList.length > 0 && fileList.length <= 5) {
                    FSS.putFile(fileList, '/asset/', (uploadLink: UploadLink[]) => {
                        assetOwnerLocation.attachFile = JSON.stringify(uploadLink)
                        createAssetOwner(assetOwnerLocation)
                    })
                } else if (fileList.length > 5) {
                    message.error('Upload File Max 5')
                    setIsLoading(false)
                } else {
                    createAssetOwner(assetOwnerLocation)
                }
            } else {
                console.log(_err)
                setIsLoading(false)
            }
        })
    }

    const createAssetOwner = (assetOwnerLocation: AssetOwnerLocation) => {
        createAssetOwnerLocation(assetOwnerLocation).then((res) => {
            setIsLoading(false)
            if (res) {
                history.push(`/assetPreview/${res.id}/${assetOwnerLocation.serialNo}`)
            }
        }).catch((err) => {
            console.log(err)
            message.error(`You have unSuccessfully save the data. ${err}`)
            setIsLoading(false)
        })
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/assetList'}>Asset List</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Create Asset</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                <div>
                    <h3 className='main-title'>Create Asset</h3>
                </div>
                <Row>
                    <Col span={24} style={{paddingLeft: 24, paddingTop: 24}}>
                        <Upload {...uploadProps}
                            listType="picture-card"
                            // className={'upload-list-inline'}
                            fileList={fileList}
                            onPreview={(e: any) => handlePreview(e)}
                        >
                            {fileList && fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Col>
                </Row>
                <Row style={{ padding: 24 }}>
                    <Form layout="vertical" onSubmit={handleSubmit}>
                        {customCheckDuplicateInput('Serial No', true, [
                            { validator: validateSerialNoUniqueness },
                            { pattern: new RegExp('^[0-9a-zA-Zก-ฮ]*$'), message: 'Please enter only alphabets or numbers'}
                        ])}
                        {customCheckDuplicateInput('Kbank Barcode', true, [{ validator: validateKbankBarcodeUniqueness }])}

                        {customSelectWithFilter('Device Type', true, deviceType, props.filterAsset.deviceType, setDeviceType)}
                        {/*{customInput('Device Type', true, deviceType, props.filterAsset.deviceType, setDeviceType)}*/}
                        {customInput('Computer Name', true, computerName, props.filterAsset.computerName, setComputerName)}
                        {customSelectWithFilter('Brand', true, brand, props.filterAsset.brand, setBrand)}
                        {customSelectWithFilter('Model', true, model, props.filterAsset.model, setModel)}
                        {customInput('Employee id', true, employeeID, props.filterAsset.employeeID, setEmployeeID)}
                        {customSelectWithFilter('Dept Name', true, deptName, props.filterAsset.deptName, setDeptName)}
                        {customSelect('Asset Status', status)}
                        {customSelectWithFilter('Location', true, location, props.filterAsset.location, setLocation, 250)}
                        {customSelectWithFilter('City', true, city, props.filterAsset.city, setCity)}
                        {customSelectWithFilter('Zone', true, zone, props.filterAsset.zone, setZone)}
                        {customInput('Monitor Brand')}
                        {customInput('Monitor Serial')}
                        {customInput('Monitor Type')}
                        {customInput('Company')}
                        {customInput('Country')}
                        {customInput('Regional')}
                        {customInput('PO Number')}
                        {customInput('Group')}
                        {customInput('Phone')}
                        {inputDate('Date in service')}
                        {inputDate('Expiry date')}
                        {inputDate('Invoice date')}
                        {customInput('Description')}
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Button type="primary" htmlType="submit" loading={isLoading} style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                            <Link to={'/assetList'}><Button style={{ float: 'right' }}>Cancel</Button></Link>
                        </Col>
                    </Form>
                </Row>
            </Card>
        </>
    )
}
const MyAssetForm = Form.create({ name: 'AssetForm' })(AssetForm)

export default connect(
    mapStateToProps,
    { getAssetFilter }
)(MyAssetForm)
