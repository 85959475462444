import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Card, Col, Empty, Form, Icon, Input, message, Modal, Row, Spin} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {Space} from '../../common-components/Space'
import {Link} from 'react-router-dom'
import AssetPreventive from './AssetPreventiveComponent'
import {AssetOwnerLocation, findBySerialNoOrKbankBarcode} from '../asset-management'
import SearchWoIdAndLocation from './SearchWoIdAndLocation'
import PreventiveForm from './PreventiveForm'
import {preventive} from './model'
import Scanner from '../Relate/Asset/BarcodeScanner'

interface Param {

}

const { Search } = Input

type Props = Param & FormComponentProps

const SearchPreventive: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)
    const [valueScanner, setValueScanner] = useState<string | undefined>(undefined)
    const [barcodeVisible, setBarcodeVisible] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isUpdatePM, setIsUpdatePM] = useState<boolean>(false)
    const [dataAsset, setDataAsset] = useState<AssetOwnerLocation | undefined>()
    const [isLoadingDataAsset, setIsLoadingDataAsset] = useState<boolean>(false)
    const [dataUpdatePM, setDataUpdatePM] = useState<preventive | undefined>(undefined)
    useEffect(() => {
        if (window.innerWidth > 765) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
    }, [window.innerWidth])

    const barcodeScanner = (value) => {
        console.log(value)
        if (value) {
            setBarcodeVisible(false)
            setFieldsValue({
                search: value
            })
            onSearch(value)
        }
    }

    const onSearch = value => {
        if (value) {
            setIsLoadingDataAsset(true)
            findBySerialNoOrKbankBarcode(value).then((res) => {
                setIsLoadingDataAsset(false)
                setDataAsset(res)
            }).catch((err) => {
                setIsLoadingDataAsset(false)
                message.error(`You have unSuccessfully Get the data. ${err}`)
            })
        }
    }

    const toInitialState = () => {
        setBarcodeVisible(false)
        setIsEdit(false)
        setIsUpdatePM(false)
        setDataAsset(undefined)
        setIsLoadingDataAsset(false)
        setDataUpdatePM(undefined)
    }

    const toViewAsset = () => {
        setIsEdit(false)
        setIsUpdatePM(false)
    }

    const getDataEdit = (data) => {
        console.log(data)
        setDataUpdatePM(data)
        setIsUpdatePM(!isUpdatePM)
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/Preventive'} onClick={() => toInitialState()}>Preventive Maintenance</Link></Breadcrumb.Item>
                {dataAsset ? <Breadcrumb.Item onClick={() => toViewAsset()}><Link to={'#'}>{dataAsset.serialNo}</Link></Breadcrumb.Item> : null}
                { isEdit ? <Breadcrumb.Item>Edit Preventive Maintenance</Breadcrumb.Item> : null}
                { isUpdatePM ? <Breadcrumb.Item>Update Preventive Maintenance</Breadcrumb.Item> : null}
            </Breadcrumb>
            <Space size={20} />
            <Card>
                {/* Edit */}
                {isUpdatePM ? null : <div>
                    {!isEdit ? <span><h3 className='main-title'>Preventive Maintenance</h3></span> : null}
                    {!isEdit ? <Row gutter={[8, 8]}>
                        <Col span={isChangeSize ? 5 : 20}>
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('search',
                                        {
                                            initialValue: valueScanner ? valueScanner!! : undefined
                                        })(
                                        <Search placeholder="Search Serial No or Kbank Barcode." allowClear onSearch={onSearch} style={{ width: '100%', margin: '0 10px' }} />
                                    )}
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            {isChangeSize ? null : <Icon style={{ marginLeft: '5%', marginTop: '3%' }} className="scan_icon" type="barcode" onClick={() => { setBarcodeVisible(true) }} />}
                        </Col>
                        <Col span={isChangeSize ? !dataAsset ? 19 : 24 : 24} style={{ textAlign: 'right', marginBottom: 10 }}>
                            <span> <Link to={'/locationForSign'}>Select location for sign</Link></span>
                            <span> / </span>
                            <span> <Link to={'/SelectLocationForPrint'}>Select location for print</Link></span>
                        </Col>
                    </Row> : null}
                    {barcodeVisible ? <Modal
                        title="Scan Barcode"
                        visible={barcodeVisible}
                        onOk={() => { setBarcodeVisible(false) }}
                        onCancel={() => { setBarcodeVisible(false) }}
                        width={700}
                        footer={null}
                    >
                        {barcodeVisible
                            ? <div style={{ textAlign: 'center' }}>
                                <Scanner barcodeScanner={barcodeScanner} />
                            </div>
                            : null}
                    </Modal> : null}
                    {dataAsset ? <span className='main-title'>{dataAsset.serialNo}</span> : null}
                    <Spin spinning={isLoadingDataAsset}>
                        {dataAsset
                            ? <div>
                                {!isEdit ? <p style={{textAlign: 'right'}} >
                                    <Button disabled={true} type='ghost' style={{marginRight: 5, marginTop: 10}} onClick={() => setIsEdit(!isEdit)}><Icon type='edit'/> Asset</Button>
                                    <Button type='ghost' style={{marginRight: 5, marginTop: 10}} onClick={() => { setIsUpdatePM(!isUpdatePM); setDataUpdatePM(undefined) }}><Icon type='sync'/> Update PM</Button>
                                </p> : null}
                                <div>
                                    <AssetPreventive assetOwnerLocation={dataAsset} isEdit={isEdit} getDataEdit={getDataEdit} setIsEdit={setIsEdit}/>
                                </div>
                            </div>
                            : <Empty style={{minHeight: !isChangeSize ? 'auto' : 420}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Spin>
                </div>}

                {/* Update PM */}
                {isUpdatePM && dataUpdatePM === undefined ? <SearchWoIdAndLocation isUpdatePM={isUpdatePM} setIsUpdatePM={setIsUpdatePM} serialNo={dataAsset?.serialNo!! || ''}/> : null}
                {isUpdatePM && dataUpdatePM !== undefined ? <PreventiveForm dataPM={dataUpdatePM} isUpdatePM={isUpdatePM} setIsUpdatePM={setIsUpdatePM} serialNo={dataAsset?.serialNo!! || ''}/> : null}
            </Card>
        </div>
    )
}

const SearchPreventiveWithFrom = Form.create<Props>({})(SearchPreventive)

export default SearchPreventiveWithFrom
