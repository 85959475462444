/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Row,
    Select,
    Table, Tooltip,
    Upload
} from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { Space } from '../../common-components/Space'
import { UploadFile } from 'antd/lib/upload/interface'
import {
    findByAssetId,
    getChangeHistoryBySerialNo,
    getSearchAllFieldValue,
    getTicketAffectedAssetBySerialNo,
    updateAssetOwnerLocation,
    getAssetFilter,
    searchFetch, findPreventiveHistoriesBySerialNo
} from './service'
import { AssetOwnerLocation, status, TicketAffectedAsset, TicketChangeHistory } from './model'
import { FormComponentProps } from 'antd/lib/form'
import { UploadLink } from '../../file-server-storage/model'
import exportIcon from '../../common-file/icon-file'
import { StoreState } from '../../store'
import { SelectValue } from 'antd/lib/select'
import TablePreventiveHistory from '../preventive/TablePreventiveHistory'
import { PMHistory } from '../preventive/model'
import { convertCamelCaseToSentenceCase } from '../../knowledge-management/util'
import { decryptDataVspace } from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        filterAsset: state.filterAsset
    }
}

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

const { Panel } = Collapse

type Props = FormComponentProps & DispatchProps & StateProps

const AssetPreview: React.FC<Props> = (props: Props) => {
    const history = useHistory()
    const { getFieldDecorator, setFieldsValue } = props.form
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [vSpaceName, setvSpaceName] = useState<string>('')
    const [assetOwnerLocation, setAssetOwnerLocation] = useState<AssetOwnerLocation>()
    // const [fileList, setFileList] = useState<UploadFile[]>()
    // const [loading, setLoading] = useState<boolean>(false)
    const { id, serialNo } = useParams<{ id: string, serialNo: string }>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [ticketAffectedAsset, setTicketAffectedAsset] = useState<TicketAffectedAsset[]>([])
    const [ticketChangeHistory, setTicketChangeHistory] = useState<TicketChangeHistory[]>([])
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [computerName, setComputerName] = useState<string[]>(props.filterAsset.computerName?.slice(0, 20) || [])
    const [historiesPreventive, setHistoriesPreventive] = useState<PMHistory[]>([])
    const [deptName, setDeptName] = useState<string[]>(props.filterAsset.deptName?.slice(0, 20) || [])

    const { Option } = Select

    useEffect(() => {
        // const email = localStorage.getItem('userName') || 'demo@vspace.in.th'
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setvSpaceName(email)
        }
        loadFilter()
        if (!serialNo) {
            history.push('/assetList')
        }
        fetchData()
        getTicketAffectedAssetBySerialNo(serialNo).then((result) => {
            if (result) {
                setTicketAffectedAsset(result)
            }
        })
        getChangeHistoryBySerialNo(serialNo).then((result) => {
            if (result) {
                setTicketChangeHistory(result)
            }
        })
        getHistoriesPreventive(serialNo)
    }, [])

    useEffect(() => {
        if (assetOwnerLocation) {
            setFieldsValue({
                Serial_No: assetOwnerLocation?.serialNo,
                Kbank_Barcode: assetOwnerLocation?.kbankBarcode,
                Device_Type: assetOwnerLocation?.deviceType,
                Computer_Name: assetOwnerLocation?.computerName,
                Brand: assetOwnerLocation?.brand,
                Model: assetOwnerLocation?.model,
                Employee_ID: assetOwnerLocation?.employeeId,
                Location: assetOwnerLocation?.location,
                Asset_Status: assetOwnerLocation?.status,
                Dept_Name: assetOwnerLocation?.deptName,
                City: assetOwnerLocation?.city,
                Zone: assetOwnerLocation?.zone,
                Monitor_Brand: assetOwnerLocation?.monitorBrand,
                Monitor_Serial: assetOwnerLocation?.monitorSerial,
                Monitor_Type: assetOwnerLocation?.monitorType,
                Company: assetOwnerLocation?.company,
                Country: assetOwnerLocation?.country,
                Regional: assetOwnerLocation?.regional,
                Group: assetOwnerLocation?.group,
                Phone: assetOwnerLocation?.phone,
                PO_Number: assetOwnerLocation?.poNumber,
                Date_in_service: assetOwnerLocation?.dateInService ? moment(assetOwnerLocation?.dateInService) : null,
                Expiry_date: assetOwnerLocation?.expiryDate ? moment(assetOwnerLocation?.expiryDate) : null,
                Invoice_date: assetOwnerLocation?.invoiceDate ? moment(assetOwnerLocation?.invoiceDate) : null,
                Description: assetOwnerLocation?.description
            })
            getHistoriesPreventive(assetOwnerLocation?.serialNo)
        }
    }, [assetOwnerLocation])

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
                setLocation(props.filterAsset.location?.slice(0, 20) || [])
                setComputerName(props.filterAsset.computerName?.slice(0, 20) || [])
                setDeptName(props.filterAsset.deptName?.slice(0, 20) || [])
            })
        }
    }

    const getHistoriesPreventive = (serialNo) => {
        findPreventiveHistoriesBySerialNo(serialNo).then((res) => {
            setHistoriesPreventive(res)
        }).catch((err) => {
            message.error(err)
        })
    }

    const fetchData = () => {
        findByAssetId(id).then((result) => {
            setAssetOwnerLocation(result)
        })
    }

    const replaceName = (name: string) => {
        return name.replace(/ /g, '_')
    }

    const customSelectWithFilter = (name: string,
        editable: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        required: boolean = false,
        setState?: Function,
        maxInput?: number
    ) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name), {
                        rules: [
                            { required: required, message: `${name} is required` }
                        ]
                    })(
                        <Select placeholder={`${name}`}
                            onSearch={(value) => { searchFetch(value, fullData || [], setState!!) }}
                            allowClear={true}
                            showSearch
                            disabled={!editable}
                            optionFilterProp="children"
                            filterOption={(inputValue, option) =>
                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        >
                            {(autoCompleteData || []).map((item, index) => {
                                return <Option value={item} key={index} title={item}>{item}</Option>
                            })}
                        </Select>
                    )}
                </Col>
            </Form.Item>
        )
    }

    const customInput = (name: string,
        editable: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        required: boolean = false,
        setState?: Function,
        maxInput?: number) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name), {
                        rules: [
                            { required: required, message: `${name} is required` }
                        ]
                    })(
                        <AutoComplete onChange={(value) => { searchFetch(value, fullData || [], setState!!) }}
                            dataSource={autoCompleteData || []} id={`${name}`} disabled={!editable}>
                            <Input style={{ maxWidth: '100%' }} type="text" placeholder={`${name}`} maxLength={maxInput} />
                        </AutoComplete>
                    )}
                </Col>
            </Form.Item>
        )
    }

    const inputDate = (name: string, date?: Date) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name))(
                        <DatePicker placeholder={`${name}`} style={{ width: '100%' }} disabled={true} />
                    )}
                </Col>
            </Form.Item>
        )
    }

    const createOption = (dataSource: string[]) => {
        const options = dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
        return options
    }

    const customSelect = (name: string, editable: boolean = false, options: string[]) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name), {
                        initialValue: options[5]
                    })(
                        <Select
                            placeholder='select status'
                            style={{ width: '100%' }}
                            disabled={!editable}
                        >{createOption(options)}
                        </Select>
                    )}
                </Col>
            </Form.Item>
        )
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const newAssetOwnerLocation: AssetOwnerLocation = {
                    ...assetOwnerLocation,
                    employeeId: values.Employee_ID,
                    location: values.Location,
                    status: values.Asset_Status,
                    computerName: values.Computer_Name,
                    lastModifiedBy: vSpaceName,
                    deptName: values.Dept_Name
                }
                updateAssetOwnerLocation(assetOwnerLocation?.id!!, newAssetOwnerLocation).then((result) => {
                    getTicketAffectedAssetBySerialNo(serialNo).then((result) => {
                        if (result) {
                            setTicketAffectedAsset(result)
                        }
                    })
                    getChangeHistoryBySerialNo(serialNo).then((result) => {
                        if (result) {
                            setTicketChangeHistory(result)
                        }
                    })
                    fetchData()
                    message.success('The update has finished successfully.')
                    setIsLoading(false)
                    setIsEdit(false)
                }).catch((err) => {
                    message.error(`You have unSuccessfully save the data. ${err}`)
                    setIsLoading(false)
                    setIsEdit(false)
                })
            }
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const fileListView = () => {
        if (assetOwnerLocation?.attachFile) {
            const attachFile = JSON.parse(assetOwnerLocation?.attachFile)
            const fileList = attachFile?.map((file: UploadLink) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    thumbUrl: exportIcon(file.type!!),
                    size: 0
                }
                return uploadFile
            })
            return (<div><Upload
                listType="picture-card"
                fileList={fileList}
                showUploadList={{ showRemoveIcon: false }}
                onPreview={(e: any) => handlePreview(e)}
                style={{ height: '200 px', width: '200 px' }}
            />
                <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>)
        }
    }

    const columnsTicketHistory = [
        {
            title: 'Ticket No.',
            dataIndex: 'ticketNumber'
        },
        {
            title: 'Subject',
            dataIndex: 'subject'
        },
        {
            title: 'Last Worklog',
            dataIndex: 'lastWorklog'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDatetime',
            render: (text, row, index) => {
                return (moment(row.createdDatetime).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: 'Resolved Date',
            dataIndex: 'closedDatetime',
            render: (text, row, index) => {
                return (moment(row.closedDatetime).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: 'Prdcat1',
            dataIndex: 'productCategorizationTier1',
            render: (text) => {
                return (
                    <>{text || '-'}
                    </>
                )
            }
        },
        {
            title: 'Prdcat2',
            dataIndex: 'productCategorizationTier2',
            render: (text) => {
                return (
                    <>{text || '-'}
                    </>
                )
            }
        },
        {
            title: 'Prdcat3',
            dataIndex: 'productCategorizationTier3',
            render: (text) => {
                return (
                    <>{text || '-'}
                    </>
                )
            }
        },
        {
            title: 'ContactPerson_Name',
            dataIndex: 'endUserContactName'
        }
    ]

    const columnsChangeHistory = [
        {
            title: 'Date/Time',
            dataIndex: 'createdDatetime',
            render: (text, row, index) => {
                return (moment(row.createdDatetime).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        // {
        //     title: 'Remedy Ticket No.',
        //     dataIndex: 'ticketId',
        // },
        {
            title: 'Field Name',
            dataIndex: 'field'
        },
        {
            title: 'From',
            dataIndex: 'oldValue'
        },
        {
            title: 'To',
            dataIndex: 'newValue'
        },
        {
            title: 'By',
            dataIndex: 'createdBy'
        }
    ]

    const columnsPreventiveMaintenanceHistory = [
        {
            title: 'Date',
            dataIndex: 'createdDatetime',
            key: 'createdDatetime',
            render: (row) => <span>{(row as string).substring(0, 10)}</span>
        },
        {
            title: 'Order ID',
            dataIndex: 'woNum',
            key: 'woNum'
        },
        {
            title: 'Assignment group',
            key: 'assignGroup',
            dataIndex: 'assignGroup'
        },
        {
            title: 'Assigned to',
            key: 'assignee',
            dataIndex: 'assignee'
        },
        {
            title: 'PM Team',
            key: 'engineerOnsite',
            dataIndex: 'engineerOnsite'
        },
        {
            title: 'PM Name',
            key: 'engineerName',
            dataIndex: 'engineerName'
        },
        {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'reason',
            render: (data: string) => (
                <>
                    <p style={{ margin: 'unset' }}>{convertCamelCaseToSentenceCase(data)}</p>
                </>
            )
        },
        {
            title: 'Note',
            key: 'note',
            dataIndex: 'note',
            render: (data: string) => (
                <>
                    <div>
                        {data?.length > 25 ? (<>
                            <Tooltip placement="bottom" title={data}>
                                {data?.substring(0, 25) + ' ...'}
                            </Tooltip>
                        </>) : (<>{data}</>)}
                    </div>
                </>
            )
        }
    ]

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/assetList'}>Asset List</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{serialNo}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card >
                {
                    !isEdit ? (
                        <Icon className="edit_icon" type="edit" onClick={() => setIsEdit(!isEdit)}
                            style={{ float: 'right' }} />
                    ) : null}
                <div>
                    <h3 className='main-title'>Asset</h3>
                </div>

                <Row gutter={10} style={{ padding: 24 }}>
                    <Col span={24}>
                        {fileListView()}
                    </Col>
                    <Form onSubmit={handleSubmit}>
                        <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                            {customInput('Serial No')}
                            {customInput('Kbank Barcode')}
                            {customInput('Device Type')}
                            {customInput('Computer Name', isEdit, computerName, props.filterAsset.computerName, true, setComputerName)}
                            {customInput('Brand')}
                            {customInput('Model')}
                            {customInput('Employee ID', isEdit, employeeID, props.filterAsset.employeeID, true, setEmployeeID, 15)}
                            {/* {customInput('Location', isEdit, location, props.filterAsset.location, true, setLocation, 250)} */}
                            {customSelectWithFilter('Location', isEdit, location, props.filterAsset.location, true, setLocation, 250)}

                            {customSelect('Asset_Status', isEdit, status)}

                        </Col>
                        <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                            {customSelectWithFilter('Dept Name', isEdit, deptName, props.filterAsset.deptName, true, setDeptName,250)}
                            {customInput('City')}
                            {customInput('Zone')}
                            {customInput('Monitor Brand')}
                            {customInput('Monitor Serial')}
                            {customInput('Monitor Type')}
                            {customInput('Company')}
                            {customInput('Country')}
                            {customInput('Regional')}
                        </Col>
                        <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                            {customInput('PO Number')}
                            {customInput('Group')}
                            {customInput('Phone')}
                            {inputDate('Date in service')}
                            {inputDate('Expiry date')}
                            {inputDate('Invoice date')}
                            {customInput('Description')}
                        </Col>
                        <Col span={24} style={{ padding: 30, backgroundColor: '#F2F2F2' }}>
                            <Col sm={24} md={12} xl={8}>
                                <Col span={24}>
                                    <span style={{ color: '#828282' }}>Data origin : </span>
                                    <span style={{ color: '#4f4f4f' }}>{assetOwnerLocation?.dataOrigin}</span>
                                </Col>
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Col span={24}>
                                    <span style={{ color: '#828282' }}>Created by : </span>
                                    <span style={{ color: '#4f4f4f' }}>{assetOwnerLocation?.createdBy}</span>
                                </Col>
                                <Col span={24}>
                                    <span style={{ color: '#828282' }}>Created datetime : </span>
                                    <span style={{ color: '#4f4f4f' }}>{assetOwnerLocation?.createdDatetime}</span>
                                </Col>
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Col span={24}>
                                    <span style={{ color: '#828282' }}>Last modified by : </span>
                                    <span style={{ color: '#4f4f4f' }}>{assetOwnerLocation?.lastModifiedBy}</span>
                                </Col>
                                <Col span={24}>
                                    <span style={{ color: '#828282' }}>Last modified datetime : </span>
                                    <span style={{ color: '#4f4f4f' }}>{assetOwnerLocation?.lastModifiedDatetime}</span>
                                </Col>
                            </Col>
                        </Col>
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Collapse defaultActiveKey={['0']} >
                                <Panel header="Ticket History" key="1">
                                    <div style={{ overflow: 'auto' }}>
                                        <Table columns={columnsTicketHistory} dataSource={ticketAffectedAsset} size="middle" />
                                    </div>
                                </Panel>
                            </Collapse>
                            <Col span={24} style={{ marginTop: 10 }}>
                            </Col>
                        </Col>
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Collapse defaultActiveKey={['0']} >
                                <Panel header="Change History" key="1">
                                    <div style={{ overflow: 'auto' }}>
                                        <Table columns={columnsChangeHistory} dataSource={ticketChangeHistory} size="middle"
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                            <Col span={24} style={{ marginTop: 10 }}>
                            </Col>
                        </Col>

                        <Col span={24} style={{ marginTop: 10 }}>
                            <Collapse defaultActiveKey={['0']} >
                                <Panel header="Preventive Maintenance History" key="1">
                                    <div style={{ overflow: 'auto' }}>
                                        <Table columns={columnsPreventiveMaintenanceHistory} dataSource={historiesPreventive} size="middle"
                                            pagination={false} />
                                    </div>
                                </Panel>
                            </Collapse>
                            <Col span={24} style={{ marginTop: 10 }}>
                            </Col>
                        </Col>
                        {
                            isEdit ? (
                                <Col span={24} style={{ marginTop: 10 }}>
                                    <Button type="primary" htmlType="submit" loading={isLoading} style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                                    <Button style={{ float: 'right' }} onClick={() => {
                                        setFieldsValue({
                                            Employee_ID: assetOwnerLocation?.employeeId,
                                            Location: assetOwnerLocation?.location,
                                            Asset_Status: assetOwnerLocation?.status,
                                            Dept_Name: assetOwnerLocation?.deptName
                                        })
                                        setIsEdit(!isEdit)
                                    }}>Cancel</Button>
                                </Col>
                            ) : null
                        }
                    </Form>
                </Row>
            </Card>
        </>
    )
}

const MyAssetPreview = Form.create({ name: 'AssetPreview' })(AssetPreview)

export default connect(
    mapStateToProps,
    { getAssetFilter }
)(MyAssetPreview)
